import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAppContext from "../../../hooks/useAppContext";
import { useUserContext } from "../../../hooks/UserContext";
import TablePro from '../../../components/newTable/TablePro';
import { serverAxios } from '../../../utils/AxiosUtils';

const AppSafeHistory = () => {
    const navigate = useNavigate();
    const { t } = useTranslation("history");
    const { popupModel } = useAppContext();
    const { showToast } = useUserContext();
    const { user, setUser, setLoading } = useUserContext();
    const [errorMessage, setErrorMessage] = useState("No Run to Show");
    const [userJobsForAppSafe, setUserJobsForAppSafe] = useState([]||'');


    useEffect(() => {
        getUserJobsHistoryForAppSafe()
    }, []);
    const handleDeleteRowOfAppSafe = (id) => {
        setLoading(true);

        serverAxios.appSafeAxios
            .post(`jobs/${id}/delete`)
            .then((res) => {
                showToast("Deleted", "success");
                getUserJobsHistoryForAppSafe();
            })
            .catch((oError) => {
                console.error(`Reading User Jobs ::::: ${oError}`);
                showToast(oError.message, "fail");
            })
            .finally(() => {
                //busy dialog of
                setLoading(false);
            });
    };
    const getUserJobsHistoryForAppSafe = () => {
        // setLoading(true);
        setUserJobsForAppSafe("Loading...")
        serverAxios.appSafeAxios
            .get("jobs")
            .then((res) => {
                const sortedUserJobs = res.data.data.sort((a, b) => {
                    return (
                        new Date(a.startTime) - new Date(b.startTime) ||
                        new Date(a.endTime) - new Date(b.endTime)
                    );
                });
                setUserJobsForAppSafe(sortedUserJobs);
            })
            .catch((oError) => {
                console.error(`Reading User Jobs ::::: ${oError.message}`);
                // showToast(oError.message, "fail");
                setUserJobsForAppSafe(oError.message)
            })
            .finally(() => {
                setLoading(false);
            });
    }
   
    const aHeadersForAppSafe = [
        {
            name: t("Run Name"),
            field: "name",
            type: "customControl",
            width: "4%",
            fnRenderCustomControl: (item) => {
                return (
                    <div
                        className="font-4 cursor-pointer"
                        onClick={() => navigate(`/history/${item.id}`)}
                    >
                        {item.name}
                    </div>
                );
            },
        },
        {
            name: t("ExecutionSet Name"),
            field: "executionSetName",
            type: "customControl",
            width: "5%",
            fnRenderCustomControl: (item) => {
                return (
                    <div
                        className="font-4"
                        onClick={() => navigate(`/history/${item.id}`)}
                    >
                        {item.executionSetName}
                    </div>
                );
            },
        },

        {
            name: t("Created Date"),
            field: "createdAt",
            type: "date",
            display: "date",
            width: "5%",
        },

        {
            name: t("Schedule Time"),
            field: "scheduledTime",
            type: "customControl",
            width: "5%",
            fnRenderCustomControl: (item) => {
                const timeInMillis = item.scheduledTime;
                const date = new Date(timeInMillis);
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour12: true, // If locale is 'en-US', use 12-hour format, otherwise use 24-hour format
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                };
                return (
                    <div className="font-4">{date.toLocaleString("en-US", options)}</div>
                );
            },
        },

        {
            name: t("Records Processed"),
            field: "recordProcessed",
            type: "text",
            width: "5%",
        },
        {
            name: t("Record Failed"),
            field: "recordFailed",
            type: "text",
            width: "5%",
        },

        {
            name: t("Start Time"),
            field: "startTime",
            type: "customControl",
            display: "time",
            width: "5%",
            fnRenderCustomControl: (item) => {
                return item.startTime
                    ? new Date(item.startTime).toLocaleTimeString()
                    : "...";
            },
        },
        {
            name: t("End Time"),
            field: "endTime",
            type: "customControl",
            display: "time",
            width: "5%",
            fnRenderCustomControl: (item) => {
                const startTime = new Date(item.startTime);
                const endTime = new Date(item.endTime);
                if (!item.startTime || !item.endTime || startTime > endTime) {
                    return "...";
                }
                return item.status === "RUNNING" ? "..." : endTime.toLocaleTimeString();
            },
        },
        {
            name: t("Run Status"),
            field: "status",
            type: "status",
            width: "5%",
        },
        {
            name: t("Action"),
            field: "",
            type: "customControl",
            width: "10%",
            fnRenderCustomControl: (item) => {
                return (
                    <div className="mid-table-col-5">
                        <div className="action-btn-h">


                            <svg
                                className="item-1 cursor-pointer"
                                onClick={async () =>
                                    await popupModel(
                                        "Removal Confirmation",
                                        "Do you wish to proceed with deleting this row?",
                                        "Delete",
                                        () => handleDeleteRowOfAppSafe(item.id)
                                    )
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_6470_50043)">
                                    <path
                                        d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                                        stroke="#667085"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_6470_50043">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                );
            },
        },
    ];
    const displayTable = () => {
        if (!userJobsForAppSafe) {
            return <div className='font-5'>No data available</div>;
        }

        if (typeof userJobsForAppSafe !== 'object') {
            return <div className='font-5'>{userJobsForAppSafe}</div>;
        }

        if (userJobsForAppSafe.length === 0) {
            return <div className='fonr-5'>No records found</div>;
        }

        return <TablePro
            columns={aHeadersForAppSafe}
            data={userJobsForAppSafe?.sort(
                (a, b) =>
                    new Date(b.createdAt) - new Date(a.createdAt)
            )}
            minRows={5}
            refresh={true}
            refreshHandler={getUserJobsHistoryForAppSafe}
            showRecords={true}
        // search={true}
        />;
    };
    return (
        <div>
            <>
                {userJobsForAppSafe.length <= 0 ? (
                    <>
                        <div className="d-flex justify-content-center font-4">
                            {errorMessage}
                        </div>
                    </>
                ) : (
                    <>

                        <div
                            className="responsive"
                            style={{ width: "99%", overflowX: "auto" }}
                        >
                            {displayTable()}
                        </div>
                    </>
                )}
            </>
        </div>
    )
}

export default AppSafeHistory
