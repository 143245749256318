import "./History.scss";
import TablePro from "../../components/newTable/TablePro";
import { useEffect, useState } from "react";
import axios from "axios";
import { useUserContext } from "../../hooks/UserContext";
import { FiInfo } from "react-icons/fi";
import { FaRegCommentDots } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import Helmet from "react-helmet";
import { serverAxios } from "../../utils/AxiosUtils";
import { useLocation } from "react-router-dom";
import useAppContext from "../../hooks/useAppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PiiHistory from "./piiHistory/PiiHistory";
import AppSafeHistory from "./APP_Safe/AppSafeHistory";
import { displayIconInByName, getUserRole } from "../../utils/userRole";

const { REACT_APP_SITE_KEY, REACT_APP_SITE_TITLE } = process.env;

const History = () => {
  const role = getUserRole();
  const { t } = useTranslation("history");

  const [userJobs, setUserJobs] = useState([]);
  const { setLoading } = useUserContext();
  const location = useLocation();
  const { state } = location;
  const [appSafeHistory, setAppSafeHistory] = useState(role?.code === 4 ? 'File Safe' : "App Safe");
  const { showToast } = useUserContext();
  const [commentMsg, setCommentMsg] = useState("");
  const tabNames = ["App Safe", "File Safe", "AI Jobs"];
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // const savedTab = localStorage.getItem('currentHistoryTab');
    // if (savedTab) {
    //   setAppSafeHistory(savedTab);
    // }
  }, []);

  const getUserJobs = () => {
    setLoading(true);
    serverAxios.fileSafeAxios
      .get("file/all-requests")
      .then((res) => {
        setUserJobs(res.data.data);
      })
      .catch((oError) => {
        console.error(`Reading User Jobs ::::: ${oError}`);
        showToast(oError.message, "fail");
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    if (state === "fileSafe") {
      setAppSafeHistory("File Safe");
    }
    getUserJobs();
  }, []);

  useEffect(() => { }, [commentMsg]);

  const buttonDisableFormatter = (row) => {
    console.log(row.status);
    if (row.status === "FINISHED") return true;
    return false;
  };

  const formatBatchJob = (text) => {
    return text.length > 10 ? text.substring(0, 8) + "..." : text;
  };

  const handleDelete = (transactionId) => {
    setLoading(true);
    axios
      .delete("/mdp/api/deletion/transaction", {
        data: {
          transactionId: transactionId,
        },
      })
      .then((res) => {
        let jobId = "";
        let updateList = userJobs.filter((job) => {
          jobId = job.transactionId === transactionId ? job.batchJobId : "";
          return job.transactionId != transactionId;
        });
        showToast(`Job ${jobId} has been Deleted!`);
        // setShowToast(true);
        getUserJobs();
      })
      .catch((oError) => {
        console.error(
          `Error when deleting User Job - ${transactionId} ::::: ${oError}`
        );
        showToast("Job delete failed", "fail");
        // setShowToast(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDownload = async (id) => {
    const oParameters = {
      responseType: "blob",
    };

    try {
      const res = await serverAxios.fileSafeAxios.get(
        `file/download/${id}`,
        oParameters
      );
      const fileData = res.data.data;

      const mimeType = fileData.type;
      let extension = "";

      if (
        mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        extension = "xlsx";
      } else if (
        mimeType === "text/csv" ||
        mimeType === "application/csv" ||
        mimeType === "text/comma-separated-values"
      ) {
        extension = "csv";
      }

      const pickerOptions = {
        suggestedName: `file.${extension}`,
        types: [
          {
            description: "Processed file",
            accept: {
              "text/csv": [".csv"],
              "application/csv": [".csv"],
              "text/comma-separated-values": [".csv"],
              "application/vnd.ms-excel": [".xlsx"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            },
          },
        ],
      };

      const fileHandle = await window.showSaveFilePicker(pickerOptions);
      const writable = await fileHandle.createWritable();

      await writable.write(fileData);
      await writable.close();

      showToast("File successfully downloaded", "success");
    } catch (error) {
      if (error.code === 20) {
        console.info("User canceled the download operation");
      } else {
        console.log("Error when downloading the file:", error);
        showToast("File download failed", "fail");
      }
    }
  };
  const aHeaders = [
    {
      name: t("jobname"),
      field: "name",
      type: "text",
      width: "5%",
    },
    {
      name: t("Comment"),
      field: "",
      type: "customControl",
      width: "5%",
      fnRenderCustomControl: (item) => {
        const handleCommentUpdate = (e) => {
          axios
            .post("/mdp/api/job/comment", {
              jobId: item.batchJobId,
              comment: commentMsg,
            })
            .then((res) => {
              showToast(`Comment has been Added/Updated!`);
            })
            .catch((oError) => {
              console.error(
                `Comment has not been Added/Updated! ::::: ${oError}`
              );
            })
            .finally(() => {
              setLoading(false);
            });
        };

        const handleOnEntering = () => {
          const oParameters = {
            params: { jobId: item.batchJobId },
          };
          setLoading(true);

          setCommentMsg(" ");

          axios
            .get("/mdp/api/job/comment", oParameters)
            .then((res) => {
              if (res.data && res.data.comment) {
                setCommentMsg(res.data.comment.comment);
              }
            })
            .catch((oError) => {
              console.error(`Reading Job Comment ::::: ${oError}`);
            })
            .finally(() => {
              setLoading(false);
            });
        };

        const popover = (
          <Popover id="popover-basic" className="commentPopover">
            <Popover.Header as="h3">Comment</Popover.Header>
            <Popover.Body>
              <form>
                <textarea
                  id="commentTextArea"
                  name="commentTextArea"
                  rows="7"
                  value={commentMsg ? commentMsg : ""}
                  onChange={(e) => setCommentMsg(e.target.value)}
                ></textarea>
              </form>
              <button
                type="submit"
                className="update"
                onClick={handleCommentUpdate}
              >
                Update
              </button>
            </Popover.Body>
          </Popover>
        );
        return (
          <OverlayTrigger
            trigger="click"
            placement="right"
            onEntering={handleOnEntering}
            overlay={popover}
            rootClose={true}
          >
            <Button className="transparent">
              <FaRegCommentDots />
            </Button>
          </OverlayTrigger>
        );
      },
    },

    {
      name: t("Created Date"),
      field: "createdAt",
      type: "date",
      display: "date",
      width: "5%",
    },
    {
      name: t("Created Time"),
      field: "createdAt",
      type: "date",
      display: "time",
      width: "5%",
    },
    {
      name: t("Group"),
      field: "groupName",
      type: "text",
      width: "5%",
    },
    {
      name: t("Status"),
      field: "status",
      type: "status",
      width: "5%",
    },
    // {
    //     name: "Record Number",
    //     field: "numberOfRecord",
    //     type: "text",
    //     width: "5%",
    // },
    {
      name: t("Records Processed"),
      field: "recordProcessed",
      type: "text",
      width: "5%",
    },
    {
      name: t("Action"),
      field: "id",
      type: "button",
      buttonType: `btn download btn-success ${buttonDisableFormatter ? "btn-success" : "disabled"
        }`,
      text: "Download",
      onClick: handleDownload,
      width: "5%",
      enabled: buttonDisableFormatter,
      // enabledFn: buttonDisableFormatter,
    },
    // {
    //     name: "",
    //     field: "requestId",
    //     type: "button",
    //     buttonType: "delete",
    //     text: "Delete",
    //     onClick: handleDelete,
    //     width: "5%",
    //     // enabled: "deletionFlag",
    //     enabledFn: buttonDisableFormatter,
    // },
    // {
    //     name: "",
    //     field: "requestId",
    //     type: "button",
    //     buttonType: "btn-info download",
    //     text: "Share",
    //     onClick: "",
    //     width: "5%",
    //     enabled: false,
    //     enabledFn: "",
    // },
  ];


  const handleTabChange = (tab) => {
    // const tab = oEven.target.innerText;
    // localStorage.setItem('currentHistoryTab', tab);
    setAppSafeHistory(tab);
  };
  const activness = (tab) => {
    // const tab = oEven.target.innerText;

    return tab === appSafeHistory ? "activeTab" : "non-activeTab";
  };
  return (
    <>
      <Helmet>
        <title>History - {REACT_APP_SITE_TITLE} </title>
      </Helmet>
      {/* <Navbar /> */}
      <div className="historyContentWrapper">
        <h3 className="font-6">{t("Jobs")}</h3>
        <div className="settingsNavbar pt-4 ">
          <ul className="row font-4">
            {tabNames.map((tabName) => (
              <li
                key={tabName}
                onClick={() => handleTabChange(tabName)}
                className={`font-6 col cursor-pointer   ${activness(tabName)}`}
              >
                <spam className='font-6 d-flex align-items-center gap-2 w-50'>{tabName} {displayIconInByName(tabName)}</spam>
              </li>
            ))}
          </ul>
        </div>
        <div className="main-content mt-4">
          <div className="table-content pt-4">
            {appSafeHistory === "App Safe" ? (
              <AppSafeHistory />
            ) : appSafeHistory === "File Safe" ? (
              <>
                <p>
                  <FiInfo color="#6941C6" className="historyInfoIcon" />{" "}
                  <span className="font-4">{t("Description")}</span>
                </p>
                <p>
                  <FiInfo color="#6941C6" className="historyInfoIcon" />{" "}
                  <span className="font-4">
                    {t("Description2")}{" "}
                    <a
                      className="font-6"
                      style={{ color: "var(--primary-700, #6941C6)" }}
                      href="mailto:info@mayadataprivacy.eu"
                    >
                      info@mayadataprivacy.eu
                    </a>{" "}
                    {t("text1")}
                  </span>
                </p>
                <div className="mt-4">
                  {userJobs.length <= 0 ? (
                    <>
                      <div className="d-flex justify-content-center font-4">
                        {t("text2")}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="responsive"
                        style={{ width: "100%", overflowX: "auto" }}
                      >
                        <TablePro
                          columns={aHeaders}
                          data={userJobs.sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          )}
                          minRows={5}
                          refresh={true}
                          refreshHandler={getUserJobs}
                          showRecords={true}
                        // search={true}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (<>
              {/* //AI History  */}
              <PiiHistory />
            </>)}
          </div>
        </div>

      </div>
    </>
  );
};

export default History;
